import React from 'react';
import Image from 'next/image';
import cn from 'classnames';
import styles from './beautiful-numbers.module.scss';

interface ImageForTariffPageProps {
  isDisabled?: boolean;
  isAnimating?: boolean;
  isFirstLoad?: boolean;
}

export const ImageForTariffPage = ({
  isAnimating,
  isFirstLoad,
  isDisabled,
}: ImageForTariffPageProps) => {

  return (
    <>
      <div className={styles.flashBloc}>
        <div
          className={cn(styles.flash, {
            [styles.disabled__flash]: isDisabled,
            [styles.flash__animated]: isAnimating || isFirstLoad,
          })}
        >
          <Image
            src='/assets/img/beautiful-numbers/flash.png'
            width={498}
            height={277}
            alt='flash'
            className={styles.background}
          />
        </div>
      </div>
      <div className={cn(styles.iphone, { [styles.disabled__phone]: isDisabled })}>
        <Image
          src='/assets/img/beautiful-numbers/iphone.png'
          width={268}
          height={716}
          alt='iphone'
        />
      </div>
    </>
  );
};
