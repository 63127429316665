import cn from 'classnames';
import { Modal } from '@mantine/core';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import { ShoppingButton } from '@/shared/ui-kit/shopping-button';
import TariffCardOption from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option';
import { useAppSelector } from '@/store/hooks';
import { formatPhoneNumber } from '@/widgets/beautiful-numbers-modal/beautiful-numbers-helpers';
import { useGetNumberCategoriesQuery } from '@/store/api/beautiful-numbers/beautiful-numbers.api';
import { MobileTariffMappedOptions } from '@/modules/tariff/shared/helpers';
import { CloseIcon } from '@/shared/assets/svg';
import { formatPrice } from '@/shared/lib/utils';
import styles from './basket-beautiful-number.module.scss';

interface Props {
  isOpened: boolean;
  isMobile: boolean;
  isDesktopMax: boolean;
  onClose: () => void;
  handler: VoidFunction;
  activeTariffData?: MobileTariffMappedOptions;
}

export const BasketBeautifulNumber = ({
  isOpened,
  isMobile,
  isDesktopMax,
  handler,
  onClose,
  activeTariffData,
}: Props) => {
  const { selectedNumber } = useAppSelector((state) => state.beautifulNumber);
  const { data: numberCategories, isLoading: isLoadingCategories } = useGetNumberCategoriesQuery({});

  const onClick = () => {
    onClose();
    handler();
  };

  const price = numberCategories?.find((el) => el.pht_id === selectedNumber?.pht_id)?.price;
  const finalPrice = (activeTariffData?.price || 0) + (price !== undefined ? price : 0);

  const content = (
    <div className={styles.content__wrapper}>
      <span className={styles.content__title}>Заказать красивый номер</span>
      <div className={styles.content__number_block}>
        {selectedNumber && <span className={styles.content__number}>{formatPhoneNumber(selectedNumber.msisdn)}</span>}
      </div>
      <div className={styles.content__block}>
        <p className={styles.content__desc}>
          Тариф для вашего номера — <b>Сделай сам</b>. Вы сможете настроить тариф или сменить его в личном кабинете или
          в офисе Волны.
        </p>
        <div className={styles.content__options}>
          {activeTariffData?.optionsMobile?.map((el, index) => (
            <TariffCardOption key={index} text={el?.text} value={el.value} icon={el.icon} purple from={el.from} />
          ))}
          <TariffCardOption text={'₽ /в мес.'} value={activeTariffData?.price} icon={'rub'} purple />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.content__button}>
          <ShoppingButton price={formatPrice(finalPrice)} handler={onClick} shortLabel />
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <>
        <div className={cn(styles.overlay, isOpened && styles.overlay_active)} onClick={onClose}></div>
        <BottomSheet blocking onDismiss={onClose} open={isOpened} className={styles.bottomsheet}>
          {content}
        </BottomSheet>
      </>
    );
  }

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      withCloseButton={false}
      radius={'24px'}
      overlayProps={{
        opacity: 0.3,
      }}
      size={isDesktopMax ? '640px' : '478px'}
      centered
      padding={0}
    >
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
      {content}
    </Modal>
  );
};
