import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import { Button, CloseButton, Input, LoadingOverlay, ScrollArea } from '@mantine/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination } from 'swiper/modules';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { Modal } from '@/shared/ui-kit';
import { BeautifulNumber } from '@/store/api/beautiful-numbers/beautiful-numbers.type';
import {
  useGetNumberCategoriesQuery,
  useGetBeautifulNumbersQuery,
} from '@/store/api/beautiful-numbers/beautiful-numbers.api';
import useIntersectionObserver from '@/shared/lib/use-intersection-observer';
import { checkCountOfNumbers, formatPhoneNumber, highlightSubsting } from './beautiful-numbers-helpers';
import { setBeautifulNumber } from '@/store/slice/beautiful-number';
import { changeHomeInternetStatus } from '@/store/slice/home-internet.slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { formatPrice } from '@/shared/lib/utils';
import { COLORS } from '@/shared/constants/colors';
import { MAX_HEIGHT } from '@/shared/constants/bottom-sheet';
import styles from './beautiful-numbers-modal.module.scss';

interface BeautifulNumberModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
}

const increaseValue = 100;

export const BeautifulNumberModal: FC<BeautifulNumberModalProps> = ({
  showModal,
  handleCloseModal,
}) => {
  const dispatch = useAppDispatch();
  const homeInternetIsAdded = useAppSelector((store) => store.homeInternet.status.added);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState({ phtId: 1, price: 0 });
  const [selectedNumber, setSelectedNumber] = useState<string | number>('');
  const [visualSearch, setVisualSearch] = useState('');
  const [search, setSearch] = useState('');
  const [find, setFind] = useState(false);
  const [offSet, setOffset] = useState(0);
  const [loadedNumbers, setLoadedNumbers] = useState<BeautifulNumber[]>([]);
  const refItem = useRef(null);
  const { data: numberCategories, isLoading: isLoadingCategories } = useGetNumberCategoriesQuery(
    {},
    { skip: !showModal },
  );

  const {
    data: beautifulNumbers,
    isLoading: isLoadingNumbers,
    isFetching,
  } = useGetBeautifulNumbersQuery(
    {
      last_id: offSet,
      price_from: activeCategoryIndex.price,
      price_to: activeCategoryIndex.price,
      favorite_number: search,
    },
    {
      skip: !showModal,
    },
  );

  const [stopFetching, setStopFetching] = useState(false);
  const { isMobile, isDesktopMax } = useMediaQuery();

  const handleClearInput = () => {
    setVisualSearch('');
    if (search) {
      setSearch('');
      setFind(false);
      setOffset(0);
      setLoadedNumbers([]);
    }
  };

  const reversedCategories = useMemo(() => {
    return numberCategories ? numberCategories.toSorted((a, b) => a.price - b.price) : [];
  }, [numberCategories]);

  const isMoreNumbersAvailable = (beautifulNumbers && !checkCountOfNumbers(beautifulNumbers, increaseValue)) || false;
  const isNeedToLoadMoreNumber = !isLoadingNumbers && !isFetching && !stopFetching && loadedNumbers.length > 99;

  const increaseOffset = () => {
    const lastId = loadedNumbers.at(-1);
    setOffset(lastId?.phone_id || 0);
  };

  const handleSelectNumber = () => {
    const selectedBeautifulNumber = loadedNumbers.find((num) => num.phone_id === selectedNumber);

    if (selectedBeautifulNumber) {
      dispatch(
        setBeautifulNumber({
          selectedNumber: {
            msisdn: selectedBeautifulNumber.msisdn,
            phone_id: selectedBeautifulNumber.phone_id,
            phone_type: selectedBeautifulNumber.phone_type,
            price: activeCategoryIndex.price,
            pht_id: activeCategoryIndex.phtId,
          },
        }),
      );
      homeInternetIsAdded && dispatch(changeHomeInternetStatus(false));
      handleCloseModal();
      setSelectedNumber('');
    }
  };

  useEffect(() => {
    setStopFetching(isMoreNumbersAvailable);

    if (beautifulNumbers?.[activeCategoryIndex.phtId] && !find) {
      const numbers = beautifulNumbers ? beautifulNumbers?.[activeCategoryIndex.phtId] : [];
      setLoadedNumbers((prev) => [...prev, ...numbers]);

      return;
    }

    setFind(false);
    setLoadedNumbers(beautifulNumbers?.[activeCategoryIndex.phtId] || []);
  }, [beautifulNumbers, isMoreNumbersAvailable]);

  useIntersectionObserver(refItem, () => {
    if (isNeedToLoadMoreNumber) {
      increaseOffset();
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ClipboardEvent<HTMLInputElement>) => {
    let value = '';
    if ('clipboardData' in e) {
      value = e.clipboardData.getData('text').replace(/\D/g, '');
      e.preventDefault();
    } else {
      value = e.target.value.replace(/\D/g, '');
    }

    if (value.length > 4) {
      value = value.slice(0, 4);
    }
    setVisualSearch(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setOffset(0);
      setSearch(visualSearch);
      setFind(true);
      setSelectedNumber('');
    }
  };

  const handleChangeCategory = (phtId: number, price: number) => () => {
    setActiveCategoryIndex({ phtId, price });
    setSelectedNumber('');
    setFind(true);
    setStopFetching(false);
    setOffset(0);
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0 });
    }
  };

  const handleClickFind = () => {
    setOffset(0);
    setSearch(visualSearch);
    setFind(true);
    setSelectedNumber('');
  };

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const noNumbers = (
    <div>
      <span className={styles.noNumbersTitle}>Номеров не найдено</span>
      <br />
      <span className={styles.noNumbersDesc}>
        По вашему запросу красивых номеров не найдено. Попробуйте ввести другую комбинацию цифр.
      </span>
    </div>
  );

  const numberCategoriesContent = useMemo(() => {
    return (
      <div className={styles.categoriesWrapper}>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          navigation={!isMobile}
          slidesPerView={'auto'}
          spaceBetween={8}
          className={styles.swiper}
        >
          {reversedCategories?.map(({ pht_id, price }, i) => (
            <SwiperSlide key={`${pht_id}` + i}>
              <div className={styles.wrapperNumbers}>
                <Button
                  variant='filled'
                  size='xs'
                  radius='md'
                  className={cn(styles.button, { [styles.activeButton]: activeCategoryIndex.phtId === pht_id })}
                  onClick={handleChangeCategory(pht_id, price)}
                >
                  {formatPrice(price)}
                </Button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }, [activeCategoryIndex, isMobile, reversedCategories]);

  const numbersContent = (
    <>
      {
        loadedNumbers.length ?
          loadedNumbers?.map(({ msisdn, phone_id }) => {
            const isActive = Number(selectedNumber) === Number(phone_id);

            return (
              <div
                key={phone_id}
                className={cn(styles.number, isActive && styles.numberActive)}
                onClick={() => setSelectedNumber(phone_id)}
              >
                {highlightSubsting(formatPhoneNumber(msisdn), visualSearch)}
              </div>
            );
          }) :
          noNumbers
      }
    </>
  );

  return (
    <div className={styles.container}>
      {isMobile ? (
        <BottomSheet
          className={styles.bottomSheetStyles}
          open={showModal}
          onDismiss={handleCloseModal}
          initialFocusRef={false}
        >
          <div className={styles.containerMobile}>
            <h1 className={styles.title}>Красивые номера</h1>
            <div className={styles.headerContentMobile}>
              <Input
                value={visualSearch}
                type='text'
                onChange={handleInputChange}
                onPaste={handleInputChange}
                classNames={{ input: styles.input }}
                className={styles.searchInputMob}
                placeholder='Введите от 1 до 4 цифр'
                rightSection={
                  visualSearch && <CloseButton className={cn(styles.clearInput)} onClick={handleClearInput} size='md' />
                }
              />
              <button
                onClick={handleClickFind}
                disabled={!visualSearch}
                className={cn(styles.pickUp, { [styles.pickUp__disabled]: !visualSearch })}
              >
                Подобрать
              </button>
            </div>
            {numberCategoriesContent}
            <div className={cn(styles.numbersWrapper, selectedNumber && styles.numbersWrapperSelected)} ref={scrollRef}>
              {numbersContent}
              <span ref={refItem}></span>
              {(isLoadingNumbers || isFetching) && (
                <div className={styles.loading}>
                  <LoadingOverlay
                    className={cn(styles.loadingRadius)}
                    visible
                    loaderProps={{ color: COLORS.volna.magenta[100] }}
                  />
                </div>
              )}
            </div>
            {selectedNumber && (
              <div className={styles.mobileBtnWrapper}>
                <button onClick={handleSelectNumber} className={cn(styles.btn, styles.pick)}>
                  Выбрать
                </button>
                <button onClick={() => setSelectedNumber('')} className={cn(styles.btn, styles.cancel)}>
                  Отмена
                </button>
              </div>
            )}
          </div>
        </BottomSheet>
      ) : (
        <Modal withoutCircle size={isDesktopMax ? '680px' : '478px'} onClose={handleCloseModal} opened={showModal}>
          <div className={styles.modalContainer}>
            <h1 className={styles.title}>Красивые номера</h1>
            <div className={styles.headerContent}>
              <Input
                type='text'
                onChange={handleInputChange}
                onPaste={handleInputChange}
                className={styles.searchInput}
                classNames={{ input: styles.input }}
                onKeyDown={handleKeyPress}
                placeholder='Введите от 1 до 4 цифр'
                value={visualSearch}
                rightSection={visualSearch && <CloseButton onClick={handleClearInput} size='md' />}
              />
              <button
                onClick={handleClickFind}
                className={cn(styles.pickUp, { [styles.pickUp__disabled]: !visualSearch })}
              >
                Подобрать
              </button>
            </div>
            {numberCategoriesContent}
            <ScrollArea.Autosize
              w='calc(100% + 8px)'
              mah={MAX_HEIGHT}
              type='always'
              offsetScrollbars
              scrollbarSize='8px'
              classNames={{ scrollbar: styles.scroll__bar, thumb: styles.scroll__thumb }}
            >
              <div className={styles.allNumbers} ref={scrollRef}>
                {numbersContent}
                <span className={styles.refContainer} ref={!isMobile && refItem}></span>
                {(isLoadingNumbers || isFetching) && (
                  <div className={styles.loading}>
                    <LoadingOverlay visible loaderProps={{ color: COLORS.volna.magenta[100] }} />
                  </div>
                )}
              </div>
            </ScrollArea.Autosize>

            {selectedNumber && (
              <div className={styles.mobileBtnWrapper}>
                <button onClick={handleSelectNumber} className={cn(styles.btn, styles.pick)}>
                  Выбрать
                </button>
                <button onClick={() => setSelectedNumber('')} className={cn(styles.btn, styles.cancel)}>
                  Отмена
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};
