'use client';

import React, { useState, useEffect, useMemo, useRef, HTMLAttributes } from 'react';
import { Button } from '@mantine/core';
import { Reset } from '@/shared/assets/svg';
import { DiamondBtn, RefreshBtn, ShoppingBasket } from '@/shared/assets/icons';
import { ImageForTariffPage } from './image-for-tariff-page';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { formatPhoneNumber } from '@/widgets/beautiful-numbers-modal/beautiful-numbers-helpers';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { setBeautifulNumber, SliceBeautifulNumber } from '@/store/slice/beautiful-number';
import { BeautifulNumberModal } from '@/widgets/beautiful-numbers-modal/beautiful-numbers-modal';
import { BasketBeautifulNumber } from './basket-beautiful-number/basket-beautiful-number';
import { formatPrice } from '@/shared/lib/utils';
import { BeautifulNumber, NumberCategory } from '@/store/api/beautiful-numbers/beautiful-numbers.type';
import { replaceNumber, rollCylinder } from '@/widgets/beautiful-numbers/utils';
import { MobileTariffMappedOptions } from '@/modules/tariff/shared/helpers';
import { resetBeautifulNumber } from '@/store/slice/beautiful-number';
import { YM } from '@/shared/constants/yandex-metrika';
import { reachGoal } from '@/shared/lib/ym';
import { volnaTheme as theme } from '@/shared/constants/theme';
import cn from 'classnames';
import styles from './beautiful-numbers.module.scss';

const OFFSET = 240;
const HEIGHT = 48;
export const INDEX = 5; // индекс номера, который виден в данный момент на "барабане".

const animationDelay: Record<number, string> = {
  4: '0.05s',
  5: '0.10s',
  7: '0.14s',
  8: '0.17s',
  9: '0.20s',
  11: '0.23s',
  12: '0.26s',
  14: '0.29s',
  15: '0.32s',
};

const animationDuration: Record<number, string> = {
  4: '0.55s',
  5: '0.60s',
  7: '0.64s',
  8: '0.67s',
  9: '0.70s',
  11: '0.73s',
  12: '0.76s',
  14: '0.79s',
  15: '0.82s',
};

interface BeautifulNumbersProps extends HTMLAttributes<HTMLDivElement> {
  onShowForm?: VoidFunction;
  beautifulNumbers: BeautifulNumber[];
  activeTariffData?: MobileTariffMappedOptions;
  numberCategories?: NumberCategory[];
  number?: string;
  page?: 'main' | 'tariff';
  price?: number;
  onClick?: () => void;
  isDisabled?: boolean;
}

function BeautifulNumbers({
  className,
  onShowForm,
  beautifulNumbers,
  activeTariffData,
  numberCategories,
  page,
  onClick,
  isDisabled,
}: BeautifulNumbersProps) {
  const dispatch = useAppDispatch();
  const { selectedNumber } = useAppSelector((state) => state.beautifulNumber);
  const { isMobile, isDesktopMax } = useMediaQuery();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const currentNumberRef = useRef<SliceBeautifulNumber>();
  const [numbers, setNumbers] = useState<SliceBeautifulNumber[]>(beautifulNumbers);
  const [counter, setCounter] = useState(0);
  const [bnModal, setBnModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState<NumberCategory>();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const isTariffPage = page !== 'main';
  const [isAddButtonPressed, setIsAddButtonPressed] = useState(false);
  const [buyButtonCounter, setBuyButtonCounter] = useState(0);

  const handleCloseModal = () => setShowModal(false);

  const leftIconShoppingButton = !isTariffPage || !isAddButtonPressed && !isDisabled ?
    <ShoppingBasket color='black' /> :
    (isAddButtonPressed ? <Reset /> : <ShoppingBasket color={theme.colors.base[500]} />);

  const handleClick = () => {
    if (!isAnimating) {
      setCounter((prev) => prev + 1);
      setIsAnimating(true);
      setActiveCategory(numberCategories?.find(({ pht_id }) => pht_id === currentNumberRef.current?.pht_id));

      setTimeout(() => {
        setIsAnimating(false);

        if (!isTariffPage) {
          dispatch(
            setBeautifulNumber({
              selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
            }),
          );
        }
      }, 850);
    }
  };

  const onHover = (isCentral: boolean) => {
    if (isCentral) {
      setIsHovered(true);
    }
  };

  const onMouseOut = () => {
    setIsHovered(false);
  };

  const buyNumber = () => {
    if (isAnimating) {
      return;
    }

    if (isTariffPage) {
      setBuyButtonCounter((prev) => prev + 1);
      setIsAddButtonPressed((prevState) => !prevState);

      if (isAddButtonPressed) {
        dispatch(resetBeautifulNumber());
      } else {
        dispatch(
          setBeautifulNumber({
            selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
          }),
        );
      }
    } else {
      setBnModal(true);
      reachGoal(YM.click_beautiful_number);
    }
  };

  const getNumberContent = (num: string, index: number) => {
    const result = [];
    for (let i = 0; i < num?.length; i++) {

      const isNotAnimatingIndex = [0, 1, 2, 6, 10, 13];
      const isNotAnimating = isNotAnimatingIndex.includes(i);
      const fadingAnimation = [2, 6, 10, 13].includes(i);
      result.push(
        <span
          key={`${index}-${i}`}
          style={{
            animationDelay: `${animationDelay[i] ? animationDelay[i] : '0s'}, 0s`,
            animationDuration: `0.5s, ${animationDuration[i] ? animationDuration[i] : '0s'}`,
            textShadow: `${!isDisabled ? '0px 4px 4px rgba(47, 14, 63, 0.25)' : 'none'} `,
          }}
          className={cn({
            [styles.animation]: (isAnimating || isFirstLoad) && !isNotAnimating,
            [styles.fadingAnimation]: (isAnimating || isFirstLoad) && fadingAnimation,
          })}
        >
          {num[i]}
        </span>
      );
    }

    return result;
  };

  const stringifiedNumbers = useMemo(() => {
    return numbers?.map((n) => {
      return formatPhoneNumber(n.msisdn);
    });
  }, [numbers]);

  useEffect(() => {
    setTimeout(() => {
      setIsFirstLoad(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (numbers?.length) {
      const i1 = rollCylinder(numbers);
      const result = rollCylinder(i1);
      setNumbers(result);
      currentNumberRef.current = result[5];
      setActiveCategory(numberCategories?.find(({ pht_id }) => pht_id === currentNumberRef.current?.pht_id));

      if (!isTariffPage) {
        dispatch(
          setBeautifulNumber({
            selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
          }),
        );
      }

    }
  }, [counter, isTariffPage]);

  useEffect(() => {
    if (selectedNumber && selectedNumber.msisdn !== currentNumberRef.current?.msisdn) {
      const result = replaceNumber(selectedNumber, numbers);
      setNumbers(result);
      currentNumberRef.current = selectedNumber;
      setActiveCategory(numberCategories?.find(({ pht_id }) => pht_id === currentNumberRef.current?.pht_id));

      if (isTariffPage) {
        if (buyButtonCounter === 0) {
          dispatch(resetBeautifulNumber());
        } else {
          setIsAddButtonPressed(true);
        }
      }
    }
  }, [selectedNumber, isTariffPage, buyButtonCounter]);

  return (
    <div
      className={cn(isTariffPage ? styles.tariffPage : styles.card, className && className, {
        [styles.card__animated]: isAnimating || isFirstLoad,
        [styles.disabled]: isDisabled && isTariffPage
      })}>

      {!isMobile && isTariffPage &&
        <ImageForTariffPage isAnimating={isAnimating} isDisabled={isDisabled} isFirstLoad={isFirstLoad} />
      }

      {!isTariffPage && <div>
        <div className={styles.link}>
          <span className={styles.link__text}>Купить красивый номер</span>
        </div>
        <div className={styles.description}>Номер, по которому тебя запомнят</div>
      </div>}
      <div className={cn(styles.content, { [styles.content__tariffPage]: isTariffPage })}>
        <div className={cn(styles.numbers, { [styles.numbers__tariffPage]: isTariffPage })}>
          <div className={styles.cylinder}>
            {stringifiedNumbers.map((n, i) => {
              return (
                <div
                  key={i}
                  style={{
                    position: 'absolute',
                    top: i * HEIGHT - OFFSET,
                  }}
                  onMouseOver={() => onHover(i === INDEX)}
                  onMouseOut={onMouseOut}
                  className={cn(styles.phoneNumber, {
                    [styles.phoneNumber__tariffPage]: isTariffPage,
                    [styles.animation]: isAnimating,
                    [styles.hovered]: isHovered && i === INDEX,
                    [styles.superFast]: isFirstLoad,
                    [styles.disabled__text]: isDisabled
                  })}
                >
                  {getNumberContent(n, i)}
                </div>
              );
            })}
          </div>
        </div>

        <div className={cn(styles.content__price, {
          [styles.content__price__tariffPage]: isTariffPage,
          [styles.disabled__text]: isDisabled && isTariffPage
        })}>
          <span>Стоимость</span> {formatPrice(activeCategory?.price)}
        </div>
      </div>

      <div className={cn(styles.buttons, { [styles.buttons__tariffPage]: isTariffPage })}>
        <Button
          classNames={{
            root: cn(styles.button__root, styles.buttons__shop, {
              [styles.button__root__tariffPage]: isTariffPage,
              [styles.button__two]: isAddButtonPressed,
            }),
          }}
          onClick={buyNumber}
          leftIcon={leftIconShoppingButton}
          disabled={isDisabled}
        >
          {!isTariffPage ? 'Купить' : (isAddButtonPressed ? 'Выбрать другой' : 'Добавить к тарифу')}
        </Button>
        <Button
          leftIcon={<DiamondBtn color={isAddButtonPressed || isDisabled ? theme.colors.base[500] : theme.colors.primary[0]} />}
          classNames={{
            root: cn(styles.button__root, styles.button__two, styles.buttons__all,
              {
                [styles.button__root__tariffPage]: isTariffPage,
                [styles.disabled__button]: isDisabled
              }
            ),
            leftIcon: styles.refreshButton__leftIcon,
          }}
          onClick={isTariffPage ? onClick : openModal}
          disabled={isTariffPage && isAddButtonPressed || isDisabled}
        >
          Все номера
        </Button>
        <Button
          leftIcon={<RefreshBtn color={isAddButtonPressed || isDisabled ? theme.colors.base[500] : theme.colors.base[0]} />}
          classNames={{
            root: cn(styles.button__root, styles.button__two, styles.buttons__next, {
              [styles.button__root__tariffPage]: isTariffPage,
            }),
            leftIcon: styles.refreshButton__leftIcon,
          }}
          onClick={handleClick}
          disabled={isAddButtonPressed || isDisabled}
        >
          {isTariffPage || isMobile ? 'Показать следующий' : 'Следующий'}
        </Button>
      </div>
      {bnModal && onShowForm && (
        <div className={styles.modal}>
          <BasketBeautifulNumber
            isOpened={bnModal}
            isMobile={isMobile}
            isDesktopMax={isDesktopMax}
            onClose={() => setBnModal(false)}
            handler={onShowForm}
            activeTariffData={activeTariffData}
          />
        </div>
      )}
      <div className={styles.modal}>
        <BeautifulNumberModal showModal={showModal} handleCloseModal={handleCloseModal} />
      </div>
    </div>
  );
}

export { BeautifulNumbers };
