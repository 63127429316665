import { RefObject, useEffect } from 'react';

const useIntersectionObserver = (
  ref: RefObject<Element>,
  onIntersect: () => void,
  options?: IntersectionObserverInit,
): void => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onIntersect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
        ...options,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, onIntersect, options]);
};

export default useIntersectionObserver;
