import { Button } from '@mantine/core';
import styles from './volna-sale.module.scss';
import cn from 'classnames';
import { HTMLAttributes } from 'react';
import Link from 'next/link';
import { LINKS } from '@/shared/constants/links';
import { useRouter } from 'next/router';

interface VolnaSaleProps extends HTMLAttributes<HTMLDivElement> {}

export function VolnaSale({ className }: VolnaSaleProps) {
  const router = useRouter();
  const toLink = () => {
    router.push(LINKS.VOLNA_SALE);
  };

  return (
    <div className={cn(styles.card, className && className)} onClick={toLink}>
      <div className={styles.headerInfo}>
        <span className={styles.header}>Волна Sale</span>
        <div className={styles.infoCol}>
          <span className={styles.info}>Продай лишние гиги, минуты или SMS - купи, что нужно</span>
          {/* <span className={styles.info}>Биржа гигабайтов, минут и SMS.</span>
          <span className={styles.info}>Есть лишний ресурс?</span>
          <span className={styles.info}>Продайте ненужное, купите нужное</span> */}
        </div>
      </div>
      <Link href={LINKS.VOLNA_SALE}>
        <Button classNames={{ root: styles.button__root }}>Подробнее</Button>
      </Link>
    </div>
  );
}
